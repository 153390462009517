@charset "UTF-8";
body {
  display: flex;
  height: 100vh;
  flex-direction: column; }

#page-wrapper {
  flex: 1 0 auto; }

#footer {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-end; }

.infographics-block {
  margin-bottom: 50px;
  display: flex;
  justify-content: center; }
  .infographics-block__item {
    margin-top: 20px;
    text-align: center;
    max-width: 300px;
    color: #6dc2f0;
    font-family: "PT Sans";
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .infographics-block__item _:-ms-lang(x), .infographics-block__item div {
      max-width: 245px; }
    .infographics-block__item:before {
      content: '';
      display: block;
      background-image: url("../images/bes.png");
      background-repeat: no-repeat;
      background-position: center;
      height: 140px;
      width: 150px;
      margin-bottom: 15px; }
    .infographics-block__item:first-child:before {
      background-image: url("../images/okom4.png"); }
    .infographics-block__item:nth-child(2):before {
      background-image: url("../images/okom2.png"); }
    .infographics-block__item:nth-child(3):before {
      background-image: url("../images/okom1.png");
      background-position-x: left; }
    .infographics-block__item:nth-child(4):before {
      background-image: url("../images/okom3.png");
      width: 200px; }

.mission-block {
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 30px; }
  .mission-block div {
    font-weight: 400;
    font-size: 16px;
    color: black; }

.bottom-text {
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
  margin: 40px auto;
  display: flex;
  align-items: center;
  color: #6dc2f0;
  padding-left: 30px;
  max-width: 550px; }
  .bottom-text:before {
    content: '';
    background: url("../images/years46.png") no-repeat;
    background-size: 100%;
    width: 111px;
    height: 134px;
    display: block;
    margin-right: 25px; }
  .bottom-text div {
    max-width: 430px; }

.table-download {
  margin: 35px 0; }
  .table-download h3 {
    color: #00c4f3;
    font-family: "PT Sans";
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 20px; }

#node-307 span {
  font-size: 100%; }

.advantsges-block {
  margin-bottom: 50px;
  display: flex;
  justify-content: space-between; }
  .advantsges-block__item {
    margin-top: 20px;
    text-align: center;
    max-width: 300px;
    color: #6dc2f0;
    font-family: "PT Sans";
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .advantsges-block__item _:-ms-lang(x), .advantsges-block__item div {
      max-width: 245px; }
    .advantsges-block__item:before {
      content: '';
      display: block;
      background-repeat: no-repeat;
      background-position: center;
      height: 150px;
      width: 170px;
      margin-bottom: 15px; }
    .advantsges-block__item:first-child:before {
      background-image: url("../images/item11.png"); }
    .advantsges-block__item:nth-child(2):before {
      background-image: url("../images/item22.png"); }
    .advantsges-block__item:nth-child(3):before {
      background-image: url("../images/item33.png");
      background-position: center; }
    .advantsges-block__item:nth-child(4):before {
      background-image: url("../images/item44.png");
      width: 200px; }

.ariston {
  text-align: center;
  color: #0563a7;
  font-family: 'Ariston';
  /* 'Marck Script', cursive; */
  font-size: 48px;
  line-height: 56px;
  font-weight: bold; }
  .ariston span {
    font-family: 'Marck Script', cursive;
    font-weight: 400; }

.ariston-slide {
  color: white;
  font-family: 'Cambria';
  font-size: 48px;
  line-height: 56px;
  text-shadow: #000 2px 1px 0px;
  font-weight: 700; }

#flexslider-1 .views-field-field-slidetext {
  position: absolute;
  max-width: 700px;
  padding-right: 20px;
  bottom: 0;
  left: 0;
  padding-left: 40px;
  padding-bottom: 20px; }

.slide_btn {
  margin-top: 20px;
  margin-right: 0;
  margin-left: auto;
  display: inline-block;
  text-indent: 0;
  background-color: #1977bb;
  color: white;
  padding: 8px 20px;
  border-radius: 5px;
  transition: .2s; }
  .slide_btn:hover {
    color: white;
    text-decoration: none;
    background-color: #004f93; }
  .slide_btn:focus {
    color: white;
    text-decoration: none; }
  .slide_btn_red {
    background-color: #d63642; }
    .slide_btn_red:hover {
      background-color: #c2222e; }

/* .button-field {
	text-align: right;
} */
/* О водице */
.about__title-block {
  margin-left: 195px;
  margin-bottom: 45px; }

.title_h1 {
  color: #00c4f3;
  font-family: "PT Sans";
  font-size: 26px;
  font-weight: 700;
  line-height: 34px; }

#node-307 p, #node-307 div {
  color: black;
  font-family: "PT Sans";
  font-size: 18px;
  font-weight: 400;
  line-height: 22px; }

.about__content {
  position: relative;
  margin-bottom: 70px; }
  .about__content h2 {
    color: #58c173;
    font-family: "PT Sans";
    font-size: 20px;
    font-weight: 700;
    line-height: 34px;
    margin-top: 35px;
    margin-bottom: 20px; }
  .about__content:before {
    content: '';
    background-size: 100%;
    background-repeat: no-repeat;
    display: block;
    position: absolute;
    left: -100px; }
  .about__content_text {
    margin-left: 195px; }
  .about__content_source:before {
    background-image: url(../images/sour.png);
    width: 222px;
    height: 152px;
    bottom: -20px; }
  .about__content_chemical:before {
    background-image: url(../images/chem.png);
    width: 204px;
    height: 202px;
    bottom: -25px; }
  .about__content_control {
    margin-bottom: 40px; }
    .about__content_control:before {
      background-image: url(../images/cont.png);
      width: 209px;
      height: 167px;
      bottom: 15px; }

#block-views-sez-block {
  margin-left: 195px; }
  #block-views-sez-block .view-content {
    display: flex; }
    #block-views-sez-block .view-content .views-row {
      margin-right: 60px; }

.about__views-docs_title {
  color: #58c173;
  font-family: "PT Sans";
  font-size: 20px;
  font-weight: 700;
  line-height: 34px; }

/* О технологии */
#node-308 h1 {
  margin-left: 195px;
  margin-bottom: 50px; }

#node-308 .span {
  position: relative; }
  #node-308 .span h3, #node-308 .span p {
    margin-left: 195px; }
  #node-308 .span h3 {
    color: #58c173;
    font-family: "PT Sans";
    font-size: 20px;
    font-weight: 700;
    line-height: 34px;
    margin-top: 0;
    margin-bottom: 20px; }
  #node-308 .span p {
    color: black;
    font-family: "PT Sans";
    font-size: 18px;
    font-weight: 400;
    line-height: 22px; }
  #node-308 .span:before {
    content: '';
    display: block;
    background-repeat: no-repeat;
    background-size: 100%;
    position: absolute;
    left: -70px; }
  #node-308 .span1:before {
    width: 209px;
    height: 211px;
    background-image: url(../images/where.png);
    bottom: 0; }
  #node-308 .span2:before {
    width: 255px;
    height: 161px;
    background-image: url(../images/podgot.png);
    bottom: 50px;
    left: -120px; }
  #node-308 .span3:before {
    width: 259px;
    height: 152px;
    background-image: url(../images/dostavka.png);
    bottom: 50px;
    left: -120px; }
  #node-308 .span4:before {
    width: 194px;
    height: 226px;
    background-image: url(../images/kiosk.png);
    bottom: 20px; }
  #node-308 .span5:before {
    width: 204px;
    height: 228px;
    background-image: url(../images/desin.png);
    bottom: 10px; }

.about_list {
  margin-top: 25px; }

.logo-text {
  text-align: center;
  font-size: 10px;
  color: #0171b6;
  font-weight: 700;
  line-height: 14px;
  font-family: 'PT Sans',  sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.6px;
  white-space: nowrap;
  margin-left: -25px; }

/* #block-block-11 {
    position: absolute;
    left: 50%;
    top: -15px;
    transform: translate(0%);	
} */
/* 
#block-block-11 {
	#telail {
		margin-top: -40px;
		padding: 10px;
		border-radius: 15px;
		div {
			display: inline-block;

			display: flex;
			    		align-items: center;
			color: black;
		    font-weight: 700;
		    text-shadow: #f0f8ff 1px 1px 5px;
		    a {
		    	color: black;
		    }
		}
	}
	.tel {
		font-weight: 700;
		&:before {
			content: '';
			background-image: url('../images/tela.png');
			display: inline-block;
			width: 14px;
			height: 20px;
			margin-right: 7px;	
		}
	}
	.mail {
		a {
			color: black;
		}
		&:before {
			content: '';
			background-image: url('../images/moail.png');
			display: inline-block;
			width: 18px;
			height: 14px;
			margin-right: 7px;	
		}
	}
} */
#telail {
  padding: 10px;
  border-radius: 15px; }
  #telail div {
    display: inline-block;
    margin-right: 20px;
    /* display: flex;
			    		align-items: center; */
    color: black;
    font-weight: 700;
    text-shadow: #f0f8ff 1px 1px 5px; }
    #telail div a {
      color: black; }
  #telail .tel {
    font-weight: 700; }
    #telail .tel:before {
      content: '';
      background-image: url("../images/tela.png");
      display: inline-block;
      width: 14px;
      height: 20px;
      margin-right: 7px; }
  #telail .mail a {
    color: black; }
  #telail .mail:before {
    content: '';
    background-image: url("../images/moail.png");
    display: inline-block;
    width: 18px;
    height: 14px;
    margin-right: 7px; }

.last.leaf a {
  padding-left: 0;
  margin-left: 0; }

#primary .last.leaf {
  position: relative;
  padding-left: 0;
  background: #3481ff;
  border-left: 0; }
  #primary .last.leaf a {
    color: aliceblue;
    text-shadow: none;
    font-weight: 700; }

.last.leaf:before {
  content: '';
  width: 0px;
  height: 100%;
  left: -15px;
  top: 0;
  position: absolute;
  border-top: 33px solid #3481ff;
  border-right: 15px solid transparent;
  transform: rotate(180deg); }

#zone {
  position: relative;
  bottom: -10px; }

/* #logo {
	top: -10px;
} */
.invisible_link {
  position: absolute;
  width: 200px;
  height: 160px; }
